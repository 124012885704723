const body = document.querySelector("body");
const boutonCGV = document.querySelector(".cgv");
let isPageCGVOpen = false;
let pageCGV;

const closeCGV = () => {
    pageCGV.classList.remove("open");
}

const createCGV = () => {
    pageCGV = document.createElement("div");
    pageCGV.classList.add("cgv-container");
    console.log('add ok!');
    pageCGV.innerHTML = `
    <section class="cgv-content">
        <h3>Conditions générales de vente</h3>
        <article>
            <h4>Vente de la carte de pêche</h4>
            <p>la vente de la carte de pêche<p>
        </article>
        <button>FERMER</button>
    </section>
`;
    body.append(pageCGV);
   // pageCGV.addEventListener("click" ,event => {
   //     event.stopPropagation();
   //     console.log("clique OK!");
   // })
};

const OpenCGV = () => {
    if(!pageCGV) {
        createCGV();
        console.log('creationCGV');
    }
    pageCGV.classList.add("open");
};

const toggleCGV = event => {
    if(isPageCGVOpen) {
        closeCGV();
        console.log('close');
    }  else {
        OpenCGV();
        console.log('open');
    }

    isPageCGVOpen = !isPageCGVOpen;
};

boutonCGV.addEventListener("click", event => {
    event.stopPropagation();
    toggleCGV();
    console.log("clique OK!");
});

window.addEventListener("click",() => {
    if(isPageCGVOpen) {
        toggleCGV();
        console.log('CGV fermé !');
    }
});

